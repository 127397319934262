<template>
  <form @submit.prevent="saveFileConfig">
    <modal-card :title="`Configure file backups`" :processing="processing">
      <div>
        <loading v-if="!site" />
        <section v-else>
          <p>
            Please enter your credentials so we can back up your website files.
            If you like, you can enter both
            <strong>SFTP (SSH)</strong> and <strong>FTP</strong> details; we'll
            default to SFTP and fallback to FTP if there is any issue
            connecting. If you're not sure where to find this information you
            can ask us for assistance.
          </p>

          <hr />

          <b-field label="Connection type">
            <b-field custom-class="is-expanded is-fullwidth">
              <b-radio-button
                v-model="method"
                native-value="ssh"
                type="is-info"
              >
                <b-icon icon="lock" size="is-small" />
                <span>SFTP (SSH)</span>
              </b-radio-button>

              <b-radio-button
                v-model="method"
                native-value="ftp"
                type="is-info"
              >
                <span>FTP</span>
              </b-radio-button>
            </b-field>
          </b-field>

          <div v-if="method === 'ssh'">
            <b-field grouped group-multiline>
              <b-field label="Hostname *" expanded>
                <b-input
                  v-model="form.sshhost"
                  :placeholder="`eg. ftp.${site.fqdn}`"
                />
              </b-field>

              <b-field label="Port *">
                <b-input
                  v-model="form.sshport"
                  :placeholder="`eg. 22`"
                  type="tel"
                  style="width: 5rem;"
                />
              </b-field>
            </b-field>

            <b-field label="Path *">
              <b-input
                v-model="form.sshpath"
                :placeholder="`eg. /path/to/website`"
              />
            </b-field>

            <b-field label="User *">
              <b-input v-model="form.sshuser" :placeholder="`Your username`" />
            </b-field>

            <b-field label="Password *">
              <b-input
                v-model="form.sshpass"
                :placeholder="`Your password`"
                type="password"
                password-reveal
              />
            </b-field>
          </div>

          <div v-if="method === 'ftp'">
            <b-field grouped group-multiline>
              <b-field label="Hostname *" expanded>
                <b-input
                  v-model="form.ftphost"
                  :placeholder="`eg. ftp.${site.fqdn}`"
                />
              </b-field>

              <b-field label="Port *">
                <b-input
                  v-model="form.ftpport"
                  :placeholder="`eg. 21`"
                  type="tel"
                  style="width: 5rem;"
                />
              </b-field>
            </b-field>

            <b-field label="Path *">
              <b-input
                v-model="form.ftppath"
                :placeholder="`eg. /path/to/website`"
              />
            </b-field>

            <b-field label="User *">
              <b-input v-model="form.ftpuser" :placeholder="`Your username`" />
            </b-field>

            <b-field label="Password *">
              <b-input
                v-model="form.ftppass"
                :placeholder="`Your password`"
                type="password"
                password-reveal
              />
            </b-field>
          </div>
        </section>
      </div>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "FileBackupConfigModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      method: "ssh",
      processing: false,
      form: {
        ftphost: "",
        ftpport: "21",
        ftpuser: "",
        ftppass: "",
        ftppath: "/",
        sshuser: "",
        sshhost: "",
        sshport: "22",
        sshpass: "",
        sshpath: "/"
      }
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    emptyFTPConfig() {
      return !this.form.ftpuser && !this.form.ftppass && !this.form.ftphost;
    },
    validFTPConfig() {
      return (
        this.form.ftpuser &&
        (this.newConfig ? this.form.ftppass : true) &&
        this.form.ftphost &&
        this.form.ftpport &&
        this.form.ftppath
      );
    },
    newConfig() {
      return !this.config.id;
    },
    emptySSHConfig() {
      return !this.form.sshuser && !this.form.sshpass && !this.form.sshhost;
    },
    validSSHConfig() {
      return (
        this.form.sshuser &&
        (this.newConfig ? this.form.sshpass : true) &&
        this.form.sshhost &&
        this.form.sshport &&
        this.form.sshpath
      );
    },
    formIsValid() {
      if (this.emptyFTPConfig && this.validSSHConfig) return true;
      if (this.emptySSHConfig && this.validFTPConfig) return true;
      if (this.validSSHConfig && this.validFTPConfig) return true;
      return false;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    },
    formPayload() {
      let payload = {
        siteid: this.siteId
      };
      if (this.validFTPConfig) {
        payload = this.$_.merge(
          payload,
          this.$_.pick(this.form, [
            "ftphost",
            "ftpport",
            "ftpuser",
            this.form.ftppass ? "ftppass" : null,
            "ftppath"
          ]),
          {
            ftpuser: this.$_.trim(this.form.ftpuser),
            ftphost: this.$_.trim(this.form.ftphost)
          }
        );
      }
      if (this.validSSHConfig) {
        payload = this.$_.merge(
          payload,
          this.$_.pick(this.form, [
            "sshuser",
            "sshhost",
            "sshport",
            this.form.sshpass ? "sshpass" : null,
            "sshpath"
          ]),
          {
            sshuser: this.$_.trim(this.form.sshuser),
            sshhost: this.$_.trim(this.form.sshhost)
          }
        );
      }
      return payload;
    }
  },
  created() {
    this.$set(
      this,
      "form",
      this.$_.merge(
        {},
        this.form,
        this.$_.pick(this.config, [
          "ftphost",
          "ftpport",
          "ftpuser",
          "ftppass",
          "ftppath",
          "sshuser",
          "sshhost",
          "sshport",
          "sshpass",
          "sshpath"
        ])
      )
    );
  },
  methods: {
    saveFileConfig() {
      if (!this.formIsDisabled) {
        const type = !this.config.fqdn
          ? "add-file-config"
          : "update-file-config";
        this.processing = true;
        this.$store
          .dispatch("sites/backups/api", {
            type,
            siteId: this.siteId,
            payload: this.formPayload
          })
          .then(result => {
            this.$emit("refresh");
            this.$toast.open({
              message: result.message,
              position: "is-bottom",
              queue: false
            });
          })
          .catch(error => {
            this.processing = false;
            this.$toast.open({
              message: error.message,
              position: "is-bottom",
              queue: false,
              type: "is-danger"
            });
          });
      }
    }
  }
};
</script>
